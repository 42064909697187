import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { EMAIL_ADDRESS } from 'constants/font-awesome';

import AdornmentIcon from 'components/addmornment-icon';

import { TextField, InputAdornment } from '@material-ui/core';

/* elseint-disable */
const alphanumeric = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
/* elseint-enable */

const EmailInput = ({ error, onUpdateParent }) => {
  const [fieldError, setFieldError] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  return <TextField
            required
            type="text"
            label="Email address"
            name="email"
            fullWidth
            margin="normal"
            variant="outlined"
            aria-label="email address"
            autoComplete="off"
            onChange={(e) => {
              const { target: { value = '' } } = e;
              value.trim();
              if (alphanumeric.test(value)) {
                setFieldError(false);
                setFieldValue(value);
                onUpdateParent(value);
              } else {
                setFieldError(true);
                setFieldValue(value);
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{
                <AdornmentIcon
                  error={error}
                  valid={fieldValue !== ''}
                  type={EMAIL_ADDRESS}
                  />
                }</InputAdornment>,
            }}
            inputProps={{ maxLength: 50 }}
            error={fieldError || (error && !fieldValue)}
            helperText={`${error && fieldValue === ''
              ? 'This is a required field, please use a valid email addresss'
              : 'Your email address. (max length 50)'}`
                   }
               />;
};

EmailInput.propTypes = {
  error: bool.isRequired,
  onUpdateParent: func.isRequired,
};

export default EmailInput;
