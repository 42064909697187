import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { EMAIL_MESSAGE } from 'constants/font-awesome';

import AdornmentIcon from 'components/addmornment-icon';

import { TextField, InputAdornment } from '@material-ui/core';

const MessageInput = ({ error, onUpdateParent }) => {
  const [fieldError, setFieldError] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  const validateLength = (val) => {
    if (!val) {
      setFieldError(true);
      return false;
    }
    if (val.length < 10) {
      setFieldError(true);
      return false;
    }
    setFieldError(false);
    return true;
  };

  return <TextField
            required
            type="text"
            label="Message"
            name="message"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete="off"
            aria-label="email message"
            multiline
            rows={5}
            onChange={(e) => {
              const { target: { value = '' } } = e;
              value.trim();
              if (validateLength(value)) {
                setFieldValue(value);
                onUpdateParent(value);
              } else {
                setFieldValue(value);
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{
                <AdornmentIcon
                  error={error}
                  valid={fieldValue !== ''}
                  type={EMAIL_MESSAGE}
                  />
              }</InputAdornment>,
            }}
            inputProps={{ maxLength: 1000, minLength: 10 }}
            error={fieldError || (error && !fieldValue)}
            helperText={`${error
              ? 'This field is required, min length 10, max length 1000.'
              : 'How can we help? (max length 1000)'}`
                   }
               />;
};

MessageInput.propTypes = {
  error: bool.isRequired,
  onUpdateParent: func.isRequired,
};

export default MessageInput;
