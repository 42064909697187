import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { EMAIL_USERNAME } from 'constants/font-awesome';

import AdornmentIcon from 'components/addmornment-icon';

import { TextField, InputAdornment } from '@material-ui/core';

const alphanumeric = RegExp(/^[a-zA-Z0-9 -_]*$/);

const NameInput = ({ error, onUpdateParent }) => {
  const [fieldError, setFieldError] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  return <TextField
            required
            type="text"
            label="Name"
            name="name"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete="off"
            aria-label="your name"
            onChange={(e) => {
              const { target: { value = '' } } = e;
              value.trim();
              if (alphanumeric.test(value)) {
                setFieldError(false);
                setFieldValue(value);
                onUpdateParent(value);
              } else {
                setFieldError(true);
                setFieldValue(value);
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{
                <AdornmentIcon
                  error={fieldError || (error && fieldValue === '')}
                  valid={fieldValue !== ''}
                  type={EMAIL_USERNAME}
                  />
              }</InputAdornment>,
            }}
            inputProps={{ maxLength: 50 }}
            error={fieldError || (error && !fieldValue)}
            helperText={`${
              fieldError
                ? 'This is a required field.'
                : `${error && fieldValue === ''
                  ? 'This field is required maximum length 50 characters.'
                  : 'Your name. (max length 50)'}`}`
                   }
               />;
};

NameInput.propTypes = {
  error: bool.isRequired,
  onUpdateParent: func.isRequired,
};

export default NameInput;
