import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import classnames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from 'gatsby-env-variables';

import { useNoticationContext } from 'contexts/notification-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';

import { isDark } from 'theme';
import {
  Grid, Hidden, Button, CircularProgress, Typography,
} from '@material-ui/core';
import NameInput from './name-input';
import EmailInput from './email-input';
import MessageInput from './message-input';

import useStyles from './styles';

const ContactUsForm = ({ onAfterSubmit = () => {}, pageLoading }) => {
  const styles = useStyles();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [error, setError] = useState({
    hasError: false,
    fieldErrors: {
      name: false,
      email: false,
      message: false,
    },
  });
  const [loading, setLoading] = useState(false);

  const noticationContext = useNoticationContext();

  const { hasError, fieldErrors } = error;

  const isValidForm = () => {
    const {
      name,
      email,
      message,
    } = formData;
    if (
      name === '' || email === '' || message === '') {
      setError({
        hasError: true,
        fieldErrors: {
          name: name === '',
          email: email === '',
          message: message === '',
        },
      });
      return false;
    }
    return true;
  };

  const submitInformation = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setLoading(true);
    if (isValidForm()) {
      const getContactService = await import('services/contact-service');
      const { emailContactUs } = getContactService.default({
        noticationContext,
      });
      const result = await emailContactUs({ email: formData, token: recaptchaToken });
      if (result) {
        onAfterSubmit({ ...formData, ...result });
      } else {
        onAfterSubmit(result);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
    <Typography
      variant="h4"
      component="h2"
      align="center"
      className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
        Email or messenger
      <FontAwesomeIcon icon={faCommentsAlt} size="lg" className={styles.headerIcon}/>
      <Typography
        variant="caption"
        component="p"
        align="right"
        className={styles.headerSubText}>
        We try our best to answer all inquiries quickly
      </Typography>
    </Typography>
    <form autoComplete="off" id="contact-us-by-email">
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >

          <Grid item xs={10} sm={10} md={8}>
          {pageLoading ? (
            <Skeleton animation="wave" height={100} className={styles.noTopMargin}/>
          ) : (
            <NameInput
              className={styles.noTopMargin}
              onUpdateParent={(name) => {
                setError({
                  hasError,
                  fieldErrors: {
                    ...fieldErrors,
                    name: !name,
                  },
                });
                setFormData({ ...formData, name });
              }}
              error={hasError && fieldErrors.name}
              />
          )}
          </Grid>
          <Grid item xs={10} sm={10} md={8} className={styles.noTopMargin}>
          {pageLoading ? (
            <Skeleton animation="wave" height={100} className={styles.noTopMargin}/>
          ) : (
          <EmailInput
            className={styles.noTopMargin}
            onUpdateParent={(email) => {
              setError({
                hasError,
                fieldErrors: {
                  ...fieldErrors,
                  description: !email,
                },
              });
              setFormData({ ...formData, email });
            }}
            error={hasError && fieldErrors.email}/>
          )}
          </Grid>
          <Grid item xs={10} sm={10} md={8} className={styles.noTopMargin}>
          {pageLoading ? (
            <Skeleton animation="wave" height={300} className={styles.noTopMargin}/>
          ) : (
            <MessageInput
              className={styles.noTopMargin}
              onUpdateParent={(message) => {
                setError({
                  hasError,
                  fieldErrors: {
                    ...fieldErrors,
                    message: !message,
                  },
                });
                setFormData({ ...formData, message });
              }}
              error={hasError && fieldErrors.message}/>
          )}
          </Grid>
          <Grid item xs={10} sm={10} md={8} className={styles.noTopMargin}>
          {pageLoading ? (
            <Skeleton animation="wave" height={50} />
          ) : (
            <>
          <Hidden smUp>
            <ReCAPTCHA
             size={'compact'}
             onChange={setRecaptchaToken}
             sitekey={RECAPTCHA_SITE_KEY}
             theme={isDark() ? 'dark' : 'light'}
             onExpired={() => setRecaptchaToken('')}
             onErrored={() => setRecaptchaToken('')}
           />
          </Hidden>
          <Hidden xsDown>
            <ReCAPTCHA
             size={'normal'}
             onChange={setRecaptchaToken}
             sitekey={RECAPTCHA_SITE_KEY}
             theme={isDark() ? 'dark' : 'light'}
             onExpired={() => setRecaptchaToken('')}
             onErrored={() => setRecaptchaToken('')}
           />
            </Hidden>
            </>
          )}
          </Grid>
          <Grid item xs={10} sm={10} md={8} className={styles.noMargin}>
          {pageLoading ? (
            <Skeleton animation="wave" height={50} className={styles.formbutton}/>
          ) : (
            <>
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              className={styles.formbutton}
              onClick={submitInformation}
              type="submit"
              fullWidth
              aria-label="send email"
              disabled={loading}
              endIcon={loading
                ? <CircularProgress className={styles.submitLoading}/>
                : (
                  <FontAwesomeIcon icon={faPaperPlane} className={styles.submitIcon}/>
                )
              }
              >
             Message
           </Button>
          </Hidden>
          <Hidden xsDown>
            <Button
              variant="contained"
              color="primary"
              className={styles.formbutton}
              onClick={submitInformation}
              type="submit"
              fullWidth
              aria-label="send email"
              disabled={loading}
              endIcon={loading
                ? <CircularProgress className={styles.submitLoading}/>
                : (
                  <FontAwesomeIcon icon={faPaperPlane} className={styles.submitIcon}/>
                )
              }
              >
             Send message
           </Button>
          </Hidden>
          </>
          )}
        </Grid>

      <Grid item xs={10} sm={10} md={8} className={styles.noTopMargin}>
        <a
          href="https://www.facebook.com/totes.loyalty"
          target="_blank"
          rel="noopener noreferrer"
          aria-label='Find us on Facebook'>
          <Typography
            variant="subtitle1"
            component="p"
            color="primary"
            align="left"
            className={styles.facebookLink}>
            Find us on Facebook
          </Typography>
        </a>
      </Grid>
    </Grid>
  </form>
  </>
  );
};

ContactUsForm.propTypes = {
  onAfterSubmit: func,
  pageLoading: bool.isRequired,
};

export default ContactUsForm;
