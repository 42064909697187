import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  bigSpaceBelow: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  noBottomMargin: {
    marginBottom: '0 !important',
    marginTop: theme.spacing(2),
  },
  noTopMargin: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
  },
  noMargin: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
  },
  saveButtonTextContainer: {
    marginTop: theme.spacing(1),
  },
  saveButtonText: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
    fontSize: theme.typography.fontSize,
  },
  fourTopBotMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headerGreen: {
    marginRight: 'auto !important',
    marginLeft: 'auto !important',
    width: '50%',
    borderBottomWidth: theme.spacing(0.2),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  headerIcon: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  headerSubText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },

  facebookLink: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
  },
});
